
const url = "https://nodepune-datastorage.mobiloitte.io/api/v1";
export const socketNotification = "ws://node.vdbcoin.com/";
export const baseUrl = "https://node.vdbcoin.com";

const user = `${url}/user`;
const admin = `${url}/admin`;

const ApiConfig = {
  userLogin: `${admin}/login`,
  listUser: `${admin}/listUser`,
  blockUnblock: `${admin}/blockUnblockUser`,
  deleteUser: `${admin}/deleteUser`,
  userDetails: `${admin}/viewUserProfile`,
  dashboardData: `${admin}/dashboard`,
  verifyOTP: `${admin}/verifyOTP`,
  forgotPassword: `${admin}/forgotPassword`, ///verify-sms-code-mobile-app
  resetPassword: `${admin}/resetPassword`,
  transactionHistoyList: `${user}/transactionList`,
  changepassword: `${admin}/changePassword`,

};

export default ApiConfig;
