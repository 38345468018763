import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/dashboard",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },



  {
    exact: true,
    path: "/verify-otp",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/node-approval",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/nodeApproval/index.js")),
  },


  {
    exact: true,
    path: "/reset-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    path: "/changePassword",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Change Password/Index")),
  },
  {
    exact: true,
    path: "/forget-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/user-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement")),
  },
  {
    exact: true,
    path: "/order-history",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/orderHistory/orderHistory")),
  },

  {
    exact: true,
    path: "/notifications",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notifications/index")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
